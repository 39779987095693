var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Music Admin")]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.sections.reduce(function (prev, ref) {
	var text = ref.text;
	var value = ref.value;

	return value === _vm.section ? text : prev;
}, ''))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.sections),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:value,attrs:{"disabled":_vm.section === value},on:{"click":function($event){_vm.section = value}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1),_c('v-spacer'),(_vm.section === 'jury')?_c('jury-help-dialog'):(_vm.section === 'stud')?_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"success"},on:{"click":function($event){return _vm.studRef.exportList()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-file-excel")]),_vm._v(" Export Students ")],1):_vm._e(),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":""}},on),[_vm._v(" "+_vm._s(_vm.termLabel)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.terms),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:'term-' + value,attrs:{"disabled":_vm.term === value},on:{"click":function($event){_vm.term = value}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1),(_vm.section === 'jury')?_c('jury-admin',{attrs:{"term":_vm.term}}):(_vm.section === 'stud')?_c('stud-admin',{ref:"studRef",attrs:{"term":_vm.term}}):(_vm.section === 'scan')?_c('scan-admin',{attrs:{"term":_vm.term}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }