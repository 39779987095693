<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Music Admin</v-toolbar-title>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text class="ml-2">
            {{ sections.reduce((prev, { text, value }) => value === section ? text : prev, '') }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ text, value } of sections" :key="value" :disabled="section === value" @click="section = value">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <!-- <span v-if="section === 'jury' && editTimesMode">Editing Times for {{ selectedInstructors[0] || selectedAccompanists[0] }}</span>
      <v-btn v-else-if="section === 'jury' && selectedInstructors.length + selectedAccompanists.length > 0" outlined @click="selectedInstructors = []; selectedAccompanists = []">
        Clear Filter
      </v-btn> -->
      <jury-help-dialog v-if="section === 'jury'"></jury-help-dialog>
      <v-btn v-else-if="section === 'stud'" outlined color="success" class="mr-2" @click="studRef.exportList()">
        <v-icon left>fal fa-file-excel</v-icon>
        Export Students
      </v-btn>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined>
            {{ termLabel }}
            <v-icon right>fas fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ text, value } of terms" :key="'term-' + value" :disabled="term === value" @click="term = value">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <jury-admin v-if="section === 'jury'" :term="term"></jury-admin>
    <stud-admin v-else-if="section === 'stud'" :term="term" ref="studRef"></stud-admin>
    <scan-admin v-else-if="section === 'scan'" :term="term"></scan-admin>
  </v-card>
</template>
<style>
.v-calendar-daily__scroll-area {
  overflow-y: hidden;
}
div.v-calendar.hideTimes div.v-calendar-daily__intervals-head, div.v-calendar.hideTimes div.v-calendar-daily__intervals-body {
  display: none;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-head {
  height: 79.5px;
  width: 100% !important;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-head::after {
  content: '10:00 AM';
  font-size: 10px;
  padding-right: 12px;
  text-align: right;
}
div.v-calendar.onlyTimes div.v-calendar-daily__intervals-body {
  width: 100% !important;
}
div.v-calendar.onlyTimes div.v-calendar-daily_head-day, div.v-calendar.onlyTimes div.v-calendar-daily__day {
  display: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}
.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: '';
}

.v-event-drag-bottom:hover::after {
  display: block;
}
</style>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    JuryHelpDialog: () => import('@/components/student/music/juryHelpDialog'),
    JuryAdmin: () => import('@/components/student/music/adminJury'),
    ScanAdmin: () => import('@/components/student/music/adminScan'),
    StudAdmin: () => import('@/components/student/music/adminStud')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)
    const sections = ref([
      { text: 'Music Juries', value: 'jury' },
      { text: 'Event Scanning', value: 'scan' },
      { text: 'Music Students', value: 'stud' }
    ])
    const section = computed({
      get: () => root.$store.state.student.music.admin.section,
      set: (section) => root.$store.commit('student/setMusicAdmin', { section })
    })

    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const terms = ref([])
    const termLabel = computed(() => terms.value.reduce((prev, { text, value }) => value === term.value ? text : prev, ''))

    onMounted(async () => {
      root.$store.commit('setSideNavActive', false)
      terms.value = []
      // Load the upcoming terms
      const { data: termData } = await root.$feathers.service('student/music/jury-slot').find({ query: { $distinct: 'term' } })
      const termCodes = []
      for (const { _id: term } of termData) {
        termCodes.push(term)
      }
      const { data } = await root.$feathers.service('system/term').find({ query: { $or: [{ term: { $in: termCodes } }, { type: 'Traditional', term: { $regex: '[\\d]{4}(05|60)' }, start: { $lte: new Date() }, end: { $gte: new Date() } }], $sort: { term: 1 } } })
      for (const { term, label } of data) {
        terms.value.push({ text: label, value: term })
      }
      terms.value.reverse()
      if (term.value === '') term.value = terms.value[0].value
    })

    const studRef = ref(null)

    return {
      user,
      roles,
      sections,
      section,
      term,
      termLabel,
      terms,
      studRef
    }
  }
}
</script>
